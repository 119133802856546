/* for phone/iphone */
@media only screen and (max-width: $screen-sm) {
  .logo {
    max-height: 80px;
  }
  .jobContainer {
    display: flex;
    flex-direction: column;
  }
  .filterContainer {
    margin-right: 0px;
    margin-bottom: 8px;
  }
  .jobTitle {
    font-size: 18px;
  }
  .jobDetailData {
    flex-direction: column;
  }
  .jobDetailData span:first-of-type {
    font-size: 14px;
  }
  .jobDetailData span:last-of-type {
    font-size: 14px;
  }
  .jobDetailsContainer {
    display: flex;
    flex-direction: column;
  }
  .jobDetailsContainer div:first-of-type {
    padding-right: 0px;
  }
  .applyButton {
    text-align: center;
    margin-bottom: 24px;
  }
  .locationContainer {
    height: 100%;
    overflow-y: auto;
    margin-left: 0;
  }
}

/* for ipad/tablet */

@media only screen and (min-width: $screen-sm) and (max-width: $screen-md) {
  .jobContainer {
    display: flex;
    flex-direction: column;
  }
  .filterContainer {
    margin-right: 0px;
    margin-bottom: 8px;
  }
  .locationContainer {
    height: 100%;
    overflow-y: auto;
    margin-left: 0;
  }
}

/* for larger than tablet format */

@media only screen and (min-width: $screen-md) {
  .filterContainer {
    height: 100%;
    min-height: 100%;
  }
  .filterCard {
    height: 100%;
    min-height: 100%;
  }
  .jobDetailData span:first-of-type {
    width: 25%;
  }
  .jobDetailData span:last-of-type {
    width: 75%;
  }
}

@media only screen and (min-width: $screen-sm) {
  .jobContainer {
    margin-left: 8%;
    margin-right: 8%;
  }
  .jobDetailContainer {
    width: 84%;
    margin-left: 8%;
    margin-right: 8%;
    margin-top: 28px;
  }

  .siteback {
    min-height: 160px;
    max-height: 160px;
  }

  .sitebackUnder {
    min-height: 190px;
    max-height: 190px;
  }

  .loadingBarWrapper {
    top: 160px;
  }
}

.react-share__ShareButton {
  padding: 4px !important;
}
