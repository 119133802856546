.chattrDataWrapper {
  margin-top: 12px;
}

.chattrCardTitle {
  font-size: 16px;
  padding: 8px;
  color: #fff;
  text-align: center;
  z-index: 5000;
  position: relative;
}

.chattrCard {
  margin-top: -3px;
  border-radius: 4px;
  background-color: #fff;
  padding-top: 11px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  z-index: 999;
  position: relative;
  margin-bottom: 12px;
  border: solid 1px #d1d5db;
}

.chattrCardNoBorder {
  margin-top: -3px;
  border-radius: 4px;
  background-color: #fff;
  padding-top: 11px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  z-index: 999;
  position: relative;
  margin-bottom: 12px;
}

.noBorder {
  border: none;
  box-shadow: none;
}
