.error_page_card {
  width: 80%;
  background-color: #fff;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  padding: 12px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error_page_container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.error_page_divider {
  width: 100%;
  border-top: 1px solid #b1b1b1;
}

.error_page_text {
  color: #b1b1b1;
  margin-top: 16px;
  font-size: 24px;
}

@media (min-width: 768px) {
  .error_page_card {
    width: 40%;
  }
  .error_page_text {
    font-size: 32px;
  }
}
