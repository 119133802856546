.theme {
  background: #fff;
  min-height: 100vh;
  padding-bottom: 40px;
  @media (min-width: 1280px) {
    .MuiContainer-maxWidthLg {
      max-width: $theme-container-width;
    }
    .job-description-wrapper.MuiContainer-maxWidthLg {
      max-width: $theme-job-wrapper-width;
    }
  }

  // Input
  .MuiInputBase-root.MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 10px 14px 10px 12px !important;
    font-size: 16px !important;
    font-family: 'proxima-nova-semi';
    line-height: 1 !important;
    display: flex;
    align-items: center;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
  }
  .MuiOutlinedInput-root {
    border-radius: 6px;
    border-color: $theme-border-color;
  }
  .MuiOutlinedInput-input {
    padding: 10px 12px;
    font-size: 16px !important;
    font-family: 'proxima-nova-semi';
    line-height: 1 !important;
    display: flex;
    align-items: center;
  }
  .MuiSelect-icon {
    top: calc(50% - 12px);
  }
  .MuiInputLabel-formControl {
    position: absolute !important;
    top: 12px !important;
    left: 13px !important;
    font-size: 16px !important;
    font-family: 'proxima-nova-semi';
    text-transform: initial !important;
    line-height: 1 !important;
    &.MuiFormLabel-filled,
    MuiFormLabel-filled {
      display: none !important;
    }
  }
  .MuiFormControl-root {
  }
  .MuiSelect-select {
    position: relative;

    & ~ .MuiSelect-icon {
      display: none;
    }
    &:before {
      content: '';
      position: absolute;
      top: 4px;
      right: 37px;
      width: 1px;
      height: calc(100% - 8px);
      background: rgb(229, 231, 235);
    }
    &:after {
      content: '';
      position: absolute;
      right: 15px;
      top: 13px;
      border: solid black;
      border-width: 0 2px 2px 0;
      padding: 3px;
      display: inline-block;
      transform: rotate(45deg);
    }
  }

  .MuiAutocomplete-popupIndicator {
    margin-left: 4px;

    .MuiIconButton-label {
      position: relative;

      svg {
        visibility: hidden;
      }
      &:after {
        content: '';
        position: absolute;
        right: 6px;
        top: 9px;
        border: solid black;
        border-width: 0 2px 2px 0;
        padding: 3px;
        display: inline-block;
        transform: rotate(45deg);
      }
    }
    &.MuiAutocomplete-popupIndicatorOpen {
      right: -7px;
      top: 2px;
    }
  }
  // Autocomplete
  .autocomplete {
    .MuiIconButton-label {
    }
    .MuiOutlinedInput-notchedOutline {
      display: none;
    }
    .MuiOutlinedInput-root {
      border-color: $theme-border-color;
    }
    .MuiInputLabel-formControl {
      position: absolute !important;
      top: 12px !important;
      left: 13px !important;
      font-size: 16px !important;
      font-family: 'proxima-nova-semi';
      text-transform: initial !important;
      line-height: 1 !important;
      &.MuiFormLabel-filled {
        display: none !important;
      }
    }

    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
      .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
      padding-right: 0 !important;
      border-radius: 6px;
      background-color: #fff;
    }

    .MuiAutocomplete-endAdornment {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 1px;
        height: 100%;
        background: rgb(229, 231, 235);
      }
    }
    .MuiAutocomplete-clearIndicator {
      margin-right: 0;
      .MuiSvgIcon-root {
        font-size: 18px !important;
      }
    }
  }

  .MuiButton-contained {
    height: 36px;
    line-height: 20px;
    border-radius: 6px;
    padding: 8px 32px;
    font-weight: normal;
    white-space: nowrap;
    &.MuiButton-label {
      font-family: 'proxima-nova';
      font-weight: normal;
    }
    &.Mui-disabled {
      color: #fff !important;
    }
  }
}

// Theme hero
.hero-header-top {
  height: 153px;
  padding: 28px 0;
  @media screen and (max-width: $screen-sm) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.hero-header-bg {
  display: flex;
  align-items: center;
  width: 100%;
  height: 432px;
  background-size: cover;
  background: rgba(0, 0, 0, 0.4);
  background-blend-mode: multiply;
}

.hero-header-title {
  color: #fff;
  font-size: 48px;
  line-height: 1.2;
  font-weight: 500;
  text-align: center;
}
.hero-header-logo {
  height: 96px;
  width: auto;
}

// Theme classic

.theme-classic {
  .logoContainer {
    @media (min-width: 1280px) {
      max-width: $theme-container-width;
    }
  }
  .orgDesc {
    padding: $theme-padding;
  }
}

// Theme block

.theme-block {
  .orgDescTitle {
    text-transform: initial;
    font-size: 30px;
    text-align: left;
    font-family: 'proxima-nova-semi';
  }
}

.block-header {
  display: flex;
  width: 100%;
  height: 576px;
  background-color: #f2f2f2;
  border-bottom: 1px solid $theme-border-color;
}
.block-header-hero {
  background-size: cover;
}
.block-header-info {
  text-align: center;
  height: 100%;
  width: 100%;
  padding: 105px 28px;
}
.block-header-tagline {
  font-family: 'proxima-nova-semi';
  font-size: 36px;
  line-height: 40px;
  text-transform: uppercase;
  margin: 56px 0;
}
.block-header-logo-container {
  max-height: 375px;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 24px;
}
.block-header-logo {
  height: 175px;
  max-width: 500px;
  margin: 0 auto;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
.block-header-content {
  width: 50%;
}
.block-header-filter-container {
  margin: 0 auto;
  padding: 0 24px;
  @media only screen and (min-width: $screen-md) {
    width: 400px;
  }
  @media only screen and (min-width: $screen-lg) {
    width: 480px;
  }
}

/* for ipad/tablet */
@media only screen and (max-width: $screen-lg) {
  .block-header-logo {
    height: 140px;
  }
}
@media only screen and (max-width: $screen-md) {
  .hero-header-logo {
    width: auto;
    height: auto;
    max-height: 96px;
    max-width: 100%;
  }
  .hero-header-title {
    width: 100%;
    text-align: center;
    font-size: 38px;
  }
  .block-header-filter-container {
    min-width: 100%;
  }
  .block-header-logo {
    height: 100px;
  }
  .block-header-tagline {
    font-size: 30px;
  }
}
@media only screen and (max-width: $screen-sm) {
  .block-header {
    height: auto;
  }
  .block-header-info {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .block-header-hero {
    display: none;
  }
  .block-header-content {
    width: 100%;
  }
  .block-header-filter-container {
    min-width: 100%;
  }
}

.theme-card {
  background-color: #fff;
  border: 1px solid $theme-border-color;
  box-shadow: $theme-box-shadow !important;
  padding: $theme-padding;
  border-radius: $theme-border-radius;
}

.theme {
  .chattrCard {
    @extend .theme-card;
  }
  .locationContainer {
  }
  .chattrCardNoBorder {
    border-radius: $theme-border-radius;
    padding: 8px;
  }
  .jobDescriptionBox {
  }
}
