.MuiButtonBase-root {
  box-shadow: none !important;
  &:after {
    display: none !important;
  }
  .MuiTouchRipple-root {
    display: none !important;
  }
}
.button-red {
  border-color: $palette-error-main !important;
  color: $palette-error-main !important;
  &:hover {
    border-color: $palette-error-main !important;
    background-color: $palette-error-main !important;
    color: #fff !important;
  }
  &:focus,
  &:active {
    border-color: darken($palette-error-main, 5%) !important;
    background-color: darken($palette-error-main, 5%) !important;
    color: #fff !important;
  }
}
.button-text-underlined {
  text-transform: none !important;

  .MuiButton-label {
    position: relative;
    &::after {
      display: block;
      content: '';
      width: 100%;
      height: 1px;
      background: #bbbbbb;
      position: absolute;
      bottom: 3px;
      left: 0;
    }
  }
}

.theme-button {
  &.MuiButton-contained {
    height: 36px;
    line-height: 20px;
    border-radius: 6px;
    padding: 8px 32px;
    font-weight: normal;
    white-space: nowrap;
  }
  .MuiButton-label {
    font-family: 'proxima-nova';
    font-weight: normal;
  }
  &.MuiButton-contained.Mui-disabled {
    color: #fff !important;
  }
}

.button-expand {
  border: none !important;
  padding: 8px 0 !important;
}
