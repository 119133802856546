.MuiOutlinedInput-notchedOutline {
  display: none !important;
}
label + .MuiInput-formControl {
  margin-top: 0 !important;
}

.MuiInputBase-root.Mui-focused:not(.MuiTablePagination-input) {
  border: 1px solid $palette-grey-main;
  box-shadow: 0px 0px 5px 2px $palette-grey-border;
}
.MuiInputAdornment-root .MuiTypography-colorTextSecondary {
  color: $palette-grey-light;
}
.MuiSelect-select:hover,
.MuiSelect-select:focus {
  background: transparent !important;
}

.MuiSelect-icon {
  color: #222222 !important;
}

.MuiIconButton-root.MuiCheckbox-root {
  margin-left: -3px;
  margin-right: 5px;
  svg {
    display: none;
  }
  .MuiIconButton-label {
    position: relative;
    width: 16px !important;
    height: 16px !important;
    border-radius: 2px !important;
    border: 1px solid $palette-grey-light !important;
    background: #fff;
  }
  &:not(.Mui-checked):hover {
    .MuiIconButton-label {
      border-color: #000 !important;
    }
  }
  &.Mui-checked {
    .MuiIconButton-label {
      background: $palette-grey-light;
      &::before {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: 10px;
        height: 10px;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.58559 6.1873C3.09863 5.60185 2.31137 4.52929 1.78688 3.84682C1.26238 3.21319 0.925059 3.74915 0.28791 4.62699C-0.424262 5.55366 0.28791 6.04079 1.29988 7.35748L3.13615 9.74683C3.81079 10.527 4.37284 9.35676 5.27217 8.23535L8.94472 3.40794C10.1063 1.9453 10.3686 1.9453 9.43168 0.775034C8.60739 -0.346359 8.64492 -0.346359 7.33322 1.40867C6.73365 2.13999 3.77336 6.04074 3.58568 6.18736L3.58559 6.1873Z' fill='white'/%3E%3C/svg%3E%0A");
      }
    }
  }

  &.Mui-disabled {
    cursor: not-allowed;
    .MuiIconButton-label {
      background: $palette-action-disabled;
    }
  }
}

.MuiTypography-caption {
  display: block;
  font-size: 12px !important;
  line-height: 1 !important;
}
.MuiFormControl-root,
.MuiAutocomplete-root {
  .MuiInputBase-root {
    transition: none;
    border: none;
    box-shadow: 0 0 0 2px $palette-grey-border;
    max-width: calc(100% - 4px);
    margin-left: 2px;
    min-height: 36px;
    border-radius: 8px;
  }
  .MuiInputBase-input:not(.MuiOutlinedInput-inputMultiline) {
    padding: 8px 30px 8px 16px;
  }
  .MuiInputBase-marginDense {
    min-height: 36px;
    border-radius: 8px;
    .MuiInputBase-input {
      padding: 8px 30px 8px 16px;
    }
  }
  .MuiInputBase-root.Mui-focused {
    border: none;
    box-shadow: 0 0 0 2px $palette-grey-dark;
  }

  .MuiInput-underline:after,
  .MuiInput-underline:before {
    display: none;
  }
  .MuiInputAdornment-positionStart .material-icons {
    font-size: 22px !important;
    fill: #222222 !important;
  }
  .MuiInputAdornment-positionEnd {
    .MuiIconButton-root {
      padding: 0 !important;
      &:not(.Mui-disabled) {
        fill: #222222 !important;
      }
    }
  }
}
.MuiInputBase-root {
  transition: all 0.25s ease;
  &.Mui-error {
    position: relative;
    box-shadow: 0 0 0 2px $palette-error-main !important;
    border: none !important;
    &::after {
      content: '!';
      color: #fff;
      background-color: $palette-error-main;
      width: 16px;
      height: 16px;
      border-radius: 16px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      right: 12px;
    }
  }
}
