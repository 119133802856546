// Color variables
$palette-primary-main: #30c67c;
$palette-primary-contrastText: #ffffff;
$palette-secondary-main: #40c9ff;
$palette-secondary-contrastText: #ffffff;
$palette-grey-dark: #293240;
$palette-grey-main: #b1b1b1;
$palette-grey-border: #e5e8ed;
$palette-grey-light: #97a3b4;
$palette-grey-background: #f9f9f9;
$palette-light-background: #e5e5e5;
$palette-grey-overlay: #293240;
$palette-grey-hover: #e5e8ed;
$palette-grey-textPrimary: #222721;
$palette-success-main: #2db77e;
$palette-error-main: #d45163;
$palette-warning-main: #f9d952;
$palette-info-main: #40c9ff;
$palette-extra-lightGrey: #e7e7e7;
$palette-extra-grey: #d8d8d8;
$palette-extra-pink: #f57a9d;
$palette-extra-brightPink: #f94ba6;
$palette-extra-yellow: #fed924;
$palette-extra-gold: #f5b529;
$palette-extra-orange: #fe953d;
$palette-extra-limeGreen: #2ee88b;
$palette-extra-forestGreen: #36ad8b;
$palette-extra-darkGreen: #3e5f61;
$palette-extra-purple: #a046e4;
$palette-extra-royalBlue: #425cff;
$palette-extra-darkBlue: #233294;
$palette-extra-system: #c9e7e5;
$palette-extra-frozen: #3865f4;
$palette-text-primary: #fff;
$palette-text-secondary: #000000;
$palette-text-disabled: #333333;
$palette-text-hint: #222721;
$palette-action-disabledBackground: #b1b1b1;
$palette-action-disabled: #999999;

// Responsive breakpoints from React
$screen-sm: 600px;
$screen-md: 900px;
$screen-lg: 1200px;
$screen-xl: 1536px;

// Theme colors
$theme-container-width: 1096px;
$theme-job-wrapper-width: 1152px;
$theme-border-color: rgb(209, 213, 219);
$theme-color: rgb(220, 38, 38);
$theme-border-radius: 8px;
$theme-padding: 24px;
$theme-box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
  rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

// Spacing
$space-1: 8px;
$space-2: 16px;
$space-3: 24px;
$space-4: 32px;

// Breakpoints
$xs: 0;
$sm: 600px;
$md: 960px;
$lg: 1280px;
$xl: 1920;

$box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.14),
  0 2px 1px -1px rgba(0, 0, 0, 0.2);
$box-shadow-2: 0 1px 8px rgba(0, 0, 0, 0.12), 0 3px 4px rgba(0, 0, 0, 0.14),
  0 3px 3px -2px rgba(0, 0, 0, 0.2);
$box-shadow-3: 0 1px 14px rgba(0, 0, 0, 0.12), 0 5px 8px rgba(0, 0, 0, 0.14),
  0 3px 5px -1px rgba(0, 0, 0, 0.2);
$box-shadow-4: 0 8px 10px rgba(0, 0, 0, 0.12), 0 6px 30px 5px rgba(0, 0, 0, 0.14),
  0 16px 24px 2px rgba(0, 0, 0, 0.2);
$box-shadow: $box-shadow-2;
