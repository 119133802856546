.job-table-container {
  box-shadow: $theme-box-shadow;
  border-radius: $theme-border-radius;
  @media only screen and (max-width: $screen-sm) {
    box-shadow: none;
    overflow: visible !important;
  }
  margin: 56px 0;
  & + .pager {
    margin-top: -56px;
  }
}
.pager + .job-table-container {
  margin-top: 0;
}
.job-table {
  border-radius: $theme-border-radius;
  th,
  tr {
    padding: 12px;
    line-height: 24px;
    height: 61px;
  }
  thead tr {
    background-color: var(--theme-color);
  }
  th {
    font-size: 18px;
    font-family: 'proxima-nova-semi';
    color: #fff;
  }
  td {
    font-size: 16px;
    font-weight: 400;
    color: #333;
    padding: 12px;
    border-right: 1px solid rgb(229, 231, 235);
    border-bottom: 1px solid rgb(229, 231, 235);
  }
  tr td:last-child {
    border-right: none;
  }
  tr:last-child {
    border-bottom: none;
  }
  @media only screen and (min-width: $screen-sm) {
    tr {
      td:first-child {
        width: 40%;
      }
      td:last-child {
        min-width: 220px;
        width: 220px;
      }
    }
  }
  @media only screen and (max-width: $screen-sm) {
    * {
      background-color: transparent;
    }
    thead {
      display: none;
    }
    th {
      height: auto;
    }
    tr {
      margin-bottom: 20px;
      border-radius: $theme-border-radius;
      overflow: hidden;
      display: block;
      height: auto;
      padding: 0;
      box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    }

    $title-width: 100px;
    td {
      display: block;
      height: auto;
      padding: 12px 12px 12px calc(#{$title-width} + 12px);
      position: relative;
      border-right: none;
    }
    td::before {
      width: $title-width;
      content: attr(data-label);
      font-weight: bold;
      font-size: 18px;
      font-family: 'proxima-nova-semi';
      color: #fff;
      padding: 12px;
      position: absolute;
      left: 0;
      top: 0;
      width: $title-width;
      height: 100%;
      z-index: 1;
      background-color: var(--theme-color);
    }
    td:last-child {
      border-bottom: 0;
    }
  }
}
.job-table-title {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
}
.job-table-detail {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
}
.job-table-location {
  color: #666;
  width: 100%;
}
.job-table-street {
  color: #666;
  width: 100%;
}
