/* Styles for Applicant Offer & Onboarding screens */

.applicant-content {
  width: 100%;
  background-color: $palette-grey-background;
  padding: 40px 16px;
  min-height: 100%;
  display: grid;
  align-content: center;
}
.applicant-content-onboarding {
  width: 100%;
  background-color: $palette-grey-background;
  padding: 20px 0;
  min-height: 100%;
  display: grid;
  align-content: center;
}
.applicant-logo {
  margin: 0 auto 32px;
  padding: 8px;
  img {
    display: block;
    height: auto;
    max-height: 80px;
    margin: auto;
  }
}
.applicant-welcome {
  display: grid;
  grid-template-areas:
    'title image'
    'text image';
  grid-gap: 10px;
  padding: 10px;
  grid-template-columns: 50% 50%;
  @media screen and (max-width: $screen-sm) {
    grid-template-areas:
      'title'
      'image'
      'text';
    grid-template-columns: 100%;
  }
}

.applicant-welcome-title {
  grid-area: title;
  font-weight: 600 !important;
  font-size: 32px !important;
  line-height: 40px !important;
  text-align: center;
  max-width: 328px;
  margin: 0 auto 40px !important;
  @media screen and (max-width: $screen-sm) {
    margin-bottom: 16px !important;
    max-width: 100%;
  }
}
.applicant-welcome-text {
  grid-area: text;
  max-width: 328px;
  margin: 0 auto;
  @media screen and (max-width: $screen-sm) {
    max-width: 100%;
  }
}
.applicant-welcome-img {
  grid-area: image;
  margin-bottom: 16px;
  img {
    object-fit: cover;
    min-height: 224px;
    height: 100%;
    @media screen and (max-width: $screen-sm) {
      min-height: 200px;
    }
  }
}
.offer-text {
  white-space: pre-line;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 16px;
}

.offer-card {
  border-radius: 8px;
  background-color: #fff;
  padding: 16px;
  z-index: 999;
  position: relative;
  margin: 0 auto 24px;
  width: 100%;
  max-width: 944px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 1px 3px rgba(0, 0, 0, 0.12);
  @media screen and (max-width: $screen-sm) {
    padding: 16px 24px !important;
  }
}
.offer-greetings {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  margin-bottom: 40px;
}
.offer-info {
  border-radius: 4px;
  border: solid 1px #333333;
  width: 100%;
  max-width: 312px;
  margin: 24px auto;
  padding: 8px;
  @media screen and (max-width: $screen-sm) {
    margin: 16px auto 0;
  }
}
.offer-info-title {
  color: $palette-grey-light;
  margin-bottom: 8px;
}
.offer-info-text {
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center;
}
.offer-term {
  border-radius: 4px;
  border: solid 1px $palette-light-background;
  width: 100%;
  max-width: 152px;
  margin: 8px auto;
  padding: 8px;
  text-align: center;
  min-height: 88px;
  display: flex;
  flex-direction: column;
}
.offer-term-title {
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center;
}
.offer-term-text-blue {
  color: #3396ff;
  margin: auto !important;
}
.offer-term-text-green {
  color: $palette-primary-main;
  margin: auto !important;
}
.login-code {
  display: flex;
  margin: 0 -4px;
  justify-content: center;
}
.code-input {
  border: 2px solid #b1b1b1;
  border-radius: 4px;
  margin: 4px;
  font-size: 20px;
  width: 48px;
  height: 48px;
  text-align: center;
  font-family: 'avant-garde';
  @media screen and (max-width: $screen-sm) {
    width: 38px;
    height: 38px;
    margin: 3px;
  }
  &.input-filled {
    border: 2px solid #000;
  }
}
.login-code-error .code-input {
  border: 2px solid $palette-error-main;
  &.input-filled {
    border: 2px solid #000;
  }
}
.benefit {
  border: 1px solid #000000;
  border-radius: 4px;
  margin: 0 auto 16px;
  max-width: 368px;
  width: 100%;
  transition: all 0.25s ease;

  .benefit-heading {
    width: 100%;
    min-height: 40px;
    padding: 16px 20px;
    margin: 0;
    text-transform: none;
  }

  .benefit-heading-inner {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .benefit-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .benefit-icon {
    margin-right: 20px;
    img {
      max-width: 32px;
      max-height: 32px;
    }
  }
  .benefit-arrow {
    min-width: 0;
    padding: 0;
    margin: 0 0 0 auto;
    & .MuiButton-startIcon {
      margin: 0;
    }
    & svg {
      font-size: 16px;
    }
  }
  .benefit-expand .benefit-arrow svg {
    transform: rotate(270deg) !important;
  }

  .benefit-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    padding: 0 20px 16px 20px;
  }
}

.profile-subheader {
  font-size: 16px !important;
  line-height: 24px !important;
}
