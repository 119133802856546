.job-description {
	.job-description-header {
		justify-content: space-between;
		flex-wrap: nowrap;
		@media screen and (max-width: $screen-sm) {
			flex-wrap: wrap;
		}
	}

	.job-description-text {
		font-size: 16px !important;
		* {
			font-size: 16px !important;
		}
		p {
			margin-block-start: 14px;
			margin-block-end: 14px;
		}
		ul {
			list-style-type: disc;
			padding-inline-start: 40px;
		}
	}

}
.job-description-title,
.job-description-title.MuiTypography-h5 {
	font-size: 36px;
	font-family: "proxima-nova";
}
.job-description-box {
	.job-description-box-subtitle {
		display: block;
		text-transform: uppercase;
		font-size: 16px;
		line-height: 24px;
		font-family: "proxima-nova-semi";

	}
}
