.locationContainer {
  height: calc(50vh + 16px);
  overflow-y: auto;
  margin: 0;
  border: 1px solid $theme-border-color;
  box-shadow: $theme-box-shadow !important;
  @media only screen and (max-width: $screen-sm) {
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;
  }

  .chattrCard {
    margin: 16px 0;
    padding: 16px !important;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &.noBorder {
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;
  }
}

.locationOpen {
  border: solid 1px #bbb !important;
}
