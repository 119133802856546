/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type='number'] {
  -moz-appearance: textfield;
}

html {
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
}

body {
  width: 100vw;
  height: 100vh;
  min-height: 100%;
  background-color: $palette-light-background;
}

#__next {
  height: 100vh;
  min-height: 100vh;
}

/* onboarding - faux bot styles */
.onboardingMenu {
  max-width: 420px;
  width: 420px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 480px) {
  .onboardingMenu {
    max-width: 360px;
    width: 360px;
  }
}
#chattr_chat_ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: auto;
}
#chattr_chat_ul li {
  display: inline-block;
  clear: both;
}
.chattr_msg_container {
  width: 100%;
  min-height: 35px;
  display: inline-block;
  clear: both;
}
.chattr_msg {
  margin: 8px;
  margin-left: 16px;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 400;
  padding: 12px;
  padding-right: 10px;
  word-wrap: break-word;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  line-height: 142%;
}
.bot_msg {
  float: left;
  text-align: left;
  background-color: #f5f5f5;
  color: #333333;
  margin-left: 24px;
  padding-left: 16px;
}

.chattr_docs_iframe {
  width: 100%;
  height: 100%;
  min-height: 1300px;
}
.chattr_docs_close {
  display: none;
}
@media only screen and (max-width: 480px) {
  .chattr_docs_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
  .chattr_docs_iframe {
    width: 100%;
    height: 100%;
    min-height: 100%;
  }
  .chattr_docs_close {
    display: block;
    position: absolute;
    top: -5px;
    right: -4px;
  }
}
