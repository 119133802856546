.pager {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 0;
}

.pageNav {
  color: #333;
  font-size: 14px;
}

.pageBack button,
.pageForward button {
  background-color: transparent !important;
  color: #333;
  font-weight: 700;
  padding: 8px;
  border-radius: 0;
  min-width: 0;
  .MuiButton-endIcon {
    margin: 0;
  }
  .MuiButton-startIcon {
    margin-left: 4px;
    margin-right: -4px;
  }
}
