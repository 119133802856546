.content {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding-bottom: 60px;
}

.centerIt {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.orgLogo {
  max-width: 85%;
  max-height: 235px;
  margin-top: 22px;
  padding-bottom: 18;
}
.siteback {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 100%;
  min-height: 140px;
  max-height: 140px;
  z-index: 100;
  box-shadow: 0 8px 12px 0px #00000020;
  background: linear-gradient(to bottom, #00000040 0%, #ffffff00 60%);
}

.sitebackUnder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 100%;
  min-height: 170px;
  max-height: 170px;
}

.logoContainer {
  display: flex;
  // not in original
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;

  border-radius: 6px;
  background-color: #ffffff;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  max-width: 700px;
  min-height: 100px;
  z-index: 101;
}

.logo {
  max-width: 100%;
  max-height: 100px;
}

.avatarContainer {
  height: 100%;
  margin-top: 12px;
  margin-bottom: 8px;
}
.avatarContainer:last-child {
  margin-right: 0;
}

.loadingBarWrapper {
  position: absolute;
  top: 140px;
  width: 100%;
}
