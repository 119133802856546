.org-desc-wrapper {
  background: #f2f2f2;
  border-top: 1px $theme-border-color solid;
  border-bottom: 1px $theme-border-color solid;
  margin-top: 30px;
}

.orgDesc {
  font-size: 16px;
  line-height: 24px;
  * {
    font-size: 16px !important;
    line-height: 24px !important;
  }
  @media only screen and (max-width: $screen-sm) {
    padding: $theme-padding 0;
  }
}

.orgDesc p {
  margin-bottom: 16px;
}

/* Editor styling */
.orgDesc {
  blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 12px 15px 12px 16px;
  }

  ol > li[data-list='bullet'] {
    list-style-type: disc;
  }

  ol > li[data-list='ordered'] {
    counter-increment: list-0;
  }

  ol > li[data-list='ordered']::marker {
    content: counter(list-0, decimal) '. ';
  }

  .ql-size-small {
    font-size: 14px !important;
  }

  .ql-size-large {
    font-size: 18px !important;
  }

  .ql-size-huge {
    font-size: 20px !important;
  }
}

.orgDescTitle {
  margin-bottom: 32px;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 500;
}

.jobs-container {
  margin-top: 32px;
  width: 100%;
}

.job-list-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #fff;
  border: 1px solid $theme-border-color;
  box-shadow: $theme-box-shadow !important;
  padding: $theme-padding;
  border-radius: 8px;
  margin-bottom: 24px;
  @media only screen and (max-width: $screen-sm) {
    padding: 16px;
    flex-direction: column;
  }
  .job-list-item-right {
    width: 200px;
    @media only screen and (max-width: $screen-sm) {
      width: 100%;
    }
  }
  .job-list-title {
    color: var(--theme-color);
    font-size: 26px;
    margin-bottom: 20px;
    font-family: 'proxima-nova-semi';
  }

  .job-list-street {
    font-family: 'proxima-nova-semi';
    @media only screen and (max-width: $screen-sm) {
      font-family: 'proxima-nova';
    }
  }

  .job-list-location {
    font-family: 'proxima-nova-semi';
    @media only screen and (max-width: $screen-sm) {
      font-family: 'proxima-nova';
    }
  }

  .job-list-details {
    font-size: 18px;
    font-family: 'proxima-nova-semi';
    @media only screen and (max-width: $screen-sm) {
      margin-bottom: 16px;
    }
  }

  .theme-button.MuiButton-contained {
    width: 100%;
    margin: 16px 0 0 0;
  }
}

.spacer {
  margin-top: 18px;
  margin-bottom: 24px;
}

.caption {
  color: #999999;
  font-size: 11px;
}

.version {
  width: 100%;
  font-size: 10px;
  color: #999999;
  text-align: right;
  background-color: #fff;
}

.noJobs {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 120px;
}

.autocomplete {
  margin-top: 6px;
}

.chattrtar_container {
  background-color: #ffffff;
  width: 44px;
  height: 44px;
  min-height: 18px;
  border-radius: 50%;
  position: relative;
  top: 1px;
  left: 2px;
  padding: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.chattrtar {
  border-radius: 50%;
  position: relative;
  top: 0;
  left: 0;
  width: 38px;
  height: 38px;
  background-size: cover;
  background-position: top center;
  box-sizing: unset;
}
.chattrtar_container_small {
  background-color: #ffffff;
  width: 32px;
  height: 32px;
  min-height: 18px;
  border-radius: 50%;
  position: relative;
  top: 1px;
  left: 2px;
  padding: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 5px 0 rgba(0, 0, 0, 0.15);
}
.chattrtar_small {
  border-radius: 50%;
  position: relative;
  top: 0;
  left: 0;
  width: 26px;
  height: 26px;
  background-size: cover;
  background-position: top center;
  box-sizing: unset;
}

.jobAvatar {
  float: left;
  margin-right: 12px;
}
