.filters-wrapper {
  margin: 32px 0 0;
}
.filter-card {
  @extend .theme-card;
  margin-bottom: 32px;
  .filter-card-title {
    h6 {
      font-family: 'proxima-nova-semi';
      font-size: 18px;
      text-transform: none;
      text-align: left;
      margin-bottom: 16px;
    }
  }
  .autocomplete {
    margin: 4px 0 8px;
  }

  .theme-button.MuiButton-contained {
    margin: 6px 0 8px;
    height: 40px;
    width: 100%;
  }
  &.filter-card-horizontal {
    .filter-card-title h6 {
      margin-bottom: 0;
    }
  }
}
