.jobDetail {
  background-color: #fff;
  margin-top: 12px;
  padding: 12px;
  margin-bottom: 18px;
}

.jobDetailContainer {
  position: relative;
  width: 100%;
  margin-top: 26px;
}

.applyButton {
  margin-top: 28px;
  margin-bottom: 12px;
}

.jobTitle {
  font-size: 22px;
  font-weight: 500;
  padding-bottom: 12px;
}

.jobDetailsContainer {
  display: flex;
  flex-direction: row;
}

.jobDetailsContainer div:first-of-type {
  width: 100%;
  padding-right: 16px;
}

.jobDetailsContainer div:last-of-type {
  width: 100%;
}

.jobDetailData {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.jobDetailData span:first-of-type {
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.75;
  margin-right: 6px;
}

.jobDetailData span:last-of-type {
  width: 100%;
  font-size: 16px;
  line-height: 1.75;
}

.jobDesc p {
  margin-block-start: 14px;
  margin-block-end: 14px;
}

.jobDesc ul {
  list-style-type: disc;
  padding-inline-start: 40px;
}

/* Editor styling */
.jobDesc {
  blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 12px 15px 12px 16px;
  }

  ol > li[data-list='bullet'] {
    list-style-type: disc;
  }

  ol > li[data-list='ordered'] {
    counter-increment: list-0;
  }

  ol > li[data-list='ordered']::marker {
    content: counter(list-0, decimal) '. ';
  }

  .ql-size-small {
    font-size: 14px;
  }

  .ql-size-large {
    font-size: 18px;
  }

  .ql-size-huge {
    font-size: 20px;
  }
}
